* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.zoom-img:hover {
  transform: scale(1.1);
  filter: brightness(.5);
  transition: all 1000ms;
}

nav.transparent-header {
  position: absolute;
  right: 0;
  left: 0;
  background-color: transparent !important;
}

nav.transparent-header .nav-link {
  color: #fff !important;
}

.nav-link {
  color: #000 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bgGreen {
  background-color: #008000;
}

.text-green {
  color: #008000;
}

.a-green {
  color: #008000;
}

.a-green:hover {
  color: white;
}

.text-pink {
  color: #d754a2;
}

.btn-orange {
  border-radius: 5px;
  padding: 5px 15px;
  text-decoration: none;
  border: 1px solid white;
}

.column {
  column-count: 2;
}

.bgGray {
  background-color: #efefef;
}

.blackbtn {
  background-color: #000;
  color: white;
}

.blackbtn:hover {
  background-color: #008000;
}

.btn-pink:hover {
  color: #000;
  background-color: #d754a2;
}

.btn-pink-outline-warning {
  border: 2px solid #d754a2;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS Below */
.navbar-nav .dropdown a {
  color: black;
}

footer {
  background-color: #000;
}

.lightTxt {
  color: #ADB3B9;
}

.text-light {
  color: #ADB3B9;
}

.lightTxt:hover {
  color: white;
}

.termsimg {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.bglight {
  background-color: #F8F9FA;
}

.footerbgimg {
  background-image: url('../src/Assets/fimg.webp');
}

.bgBlack {
  background-color: #000;
}

.mapbg {
  background-image: url('../src/Assets/imap.webp');
  background-repeat: no-repeat;
  height: 700px;
  position: relative;
  background-position: center;
  background-size: contain;
}

.mapimg1 {
  position: absolute;
  left: 30%;
  top: 30%;
}

.mapimg2 {
  position: absolute;
  left: 33%;
  top: 48%;
}

.mapimg3 {
  position: absolute;
  left: 33%;
  top: 62%;
}

.mapimg4 {
  position: absolute;
  left: 33%;
  top: 70%;
}

.mapimg5 {
  position: absolute;
  left: 30%;
  top: 43%;
}

.mapimg6 {
  position: absolute;
  left: 38%;
  top: 74%;
}

.mapimg7 {
  position: absolute;
  left: 25%;
  top: 35%;
}

.mapimg8 {
  position: absolute;
  left: 30%;
  top: 55%;
}

hr {
  color: #040b07;
}

.bglightGreen {
  background-color: transparent;
  background-image: linear-gradient(360deg, #13a854ea 16%, #7bc027 95%);
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  background-image: url('../src/Assets/cnt1.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.headingimg {
  background: url('./Assets/hp11.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.headingimgnew {
  background: url('./Assets/hp12.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
}

.headingimgnew2 {
  background: url('./Assets/headingImgNew2.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
}

.headingimgnew1 {
  background: url('./Assets/polycarbonate.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
}

.headingimgnew3 {
  background: url('./Assets/gallery/WireRopeNetHouse.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
}

.headingimgtunnel {
  background: url('./Assets/TunnelShadenet.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
}

.bannerParent {
  position: relative;
  height: 800px;
}

.bannerimg {
  background-image: url('./Assets/new/b1.webp');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 800px;
}

.bg-text {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  padding: 70px;
  text-align: center;
  height: 800px;
}

.whitebgimg {
  background-image: url('./Assets/videobg.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.footerimg2 {
  background-image: url('./Assets/footer-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.bgyellow {
  background-color: #313718;
}

.bgolivegreen {
  background-color: #808000;
}

.text-yellow {
  color: #e2ba4b;
}

.imghover {
  transition: all 1000ms;
}

.imghover:hover {
  filter: brightness(.5);
  transform: scale(1.2);
}

.sliderbg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../src/Assets/customerslider.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.rotate:hover {
  transform: rotate(360deg); /* Updated to transform instead of rotate */
  transition-duration: 1s;
}

.curveImg {
  border: solid 1px transparent;
  border-radius: 0 0 50% 50%;
}

.curveImg:hover {
  border-radius: 0 0;
  transition-duration: 1s;
}

.heading1 {
  font-size: 55px;
  font-weight: bold;
  line-height: 1.2;
}

.heading2 {
  font-size: 30px;
  font-weight: bolder;
}

.heading3 {
  font-size: 26px;
  font-weight: bolder;
}



/* General Styles */
.text {
  padding: 0;
  margin: 0;
  transform: translateX(-200%);
  opacity: 0;
  animation: slide-in-anim 1.5s ease-out forwards;
}

@keyframes slide-in-anim {
  20% {
      opacity: 0;
  }
  100% {
      opacity: 1;
      transform: translateX(0%);
  }
}

/* Banner Styles */
.bannerParent {
  position: relative;
  height: 800px;
}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(100%);
}

#contentVid {
  position: relative;
  z-index: 1;
  bottom: 0;
  background-size: cover;
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  padding: 50px;
}
.text-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; 
  padding: 0 20px; 
}

.text-overlay h1 {
  font-size: 2rem; 
}


.bannerimg {
  background-image: url('./Assets/rvid.mp4');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 800px;
}

/* Text Alignment */
.textJustify {
  text-align: justify;
  text-justify: inter-word;
}

/* Presentation Size */
.pptSize {
  width: 100%;
  height: 600px;
}

@media screen and (max-width: 768px) {
  .pptSize {
      height: 400px;
  }
  .heading1 {
      font-size: 14px;
  }
  .heading2 {
      font-size: 12px;
  }
  .heading3 {
      font-size: 11px;
  }

  .text-overlay h1 {
    font-size: 1.5rem; 
  }

  .text-overlay h5 {
    font-size: 1rem;
  }

  #contentVid h1 {
    font-size: 1.5rem; /* Adjust this value as needed for mobile view */
  }
  #contentVid .heading1 {
    font-size: 1.2rem; /* Specific size for heading1 on mobile */
  }
  #contentVid .heading3 {
    font-size: 1rem; /* Specific size for heading3 on mobile */
  }
  #contentVid .heading6 {
    font-size: 0.9rem; /* Specific size for heading6 on mobile */
  }
  /* Map Images */
  .mapimg1 { left: 17%; top: 33%; }
  .mapimg2 { left: 26%; top: 45%; }
  .mapimg3 { left: 19%; top: 57%; }
  .mapimg4 { left: 28%; top: 59%; }
  .mapimg5 { left: 15%; top: 47%; }
  .mapimg6 { left: 24%; top: 65%; }
  .mapimg7 { left: 8%; top: 40%; }
  .mapimg8 { left: 20%; top: 51%; }
}

/* Square and Box Shadow Styles */
.square {
  background: linear-gradient(108.7deg, rgb(224, 235, 213) 8.1%, rgb(87, 171, 99) 91.2%);
}

.boxShadow {
  box-shadow: 0px 0px 14px rgb(108, 108, 150);
}

.bggreendark {
  background-color: #69a81d;
}

/* Text Shadow and Justification */
.text-justify {
  text-align: justify;
}

.text-shadow {
  text-shadow: 5px 5px 15px rgba(147, 9, 9, 0.3);
}

/* Animation for Text */
.animationForText {
  color: rgb(20, 123, 66);
  font-family: "Arial", sans-serif; 
  font-size: 3em;
  margin: 10px 0 0 10px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  animation: animtext 4s steps(80, end) forwards; 
  transition: width 2s cubic-bezier(0.1, 0.7, 1.0, 0.1);
}

.animationForText:hover {
  animation: animtextHover 4s steps(80, end) forwards;
}

@keyframes animtext {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes animtextHover {
  from { width: 0; }
  to { width: 100%; }
}

/* Hover Text */
.hover-text {
  transition: color 0.3s; 
}

.hover-text:hover {
  color: rgb(17, 111, 25); 
}

/* Quote Card Animation */
@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

.reveal {
  opacity: 0;
  animation: fadeInUp 0.8s ease-in-out forwards;
}

.reveal.delay-1 { animation-delay: 0.2s; }
.reveal.delay-2 { animation-delay: 0.4s; }
.reveal.delay-3 { animation-delay: 0.6s; }
.reveal.delay-4 { animation-delay: 0.8s; }
.reveal.delay-5 { animation-delay: 1.0s; }
.reveal.delay-6 { animation-delay: 1.2s; }
.reveal.delay-7 { animation-delay: 1.4s; }
.reveal.delay-8 { animation-delay: 1.6s; }

/* Cart Modal Styles */
.cart-item {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.cart-item img {
  border-radius: 4px;
}

.cart-item-details {
  flex-grow: 1;
  margin-left: 10px;
}

.cart-item-quantity button {
  width: 30px;
  height: 30px;
}

.cart-modal {
  position: fixed;
  top: 20%;
  right: 20%;
  width: 400px; /* Increase width */
  max-height: 40%; /* Adjust max height */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto; /* Allow scrolling if content overflows */
  padding: 20px; /* Add padding */
}


/* General Styles */
.close-button {
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  float: right;
}

/* Cart Item Styles */
.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cart-item-image {
  width: 50px; /* Smaller image size */
  height: auto;
  margin-right: 10px;
}

.cart-item-details {
  flex-grow: 1;
}

.quantity-controls {
  display: flex;
  align-items: center;
}

.quantity-controls button {
  margin: 0 5px;
}

/* Product1 Styles */
.product-container {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  padding: 20px;
}

.product-image {
  width: 300px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between image and text */
}

.product-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left */
}

.product-name {
  font-size: 24px; /* Increased size */
  margin: 10px 0;
  font-weight: bold;
}

.product-price {
  font-size: 20px; /* Increased size */
  margin: 10px 0;
}

.add-to-cart-button {
  font-size: 18px; /* Increased size */
  padding: 10px 20px;
  margin: 10px 0;
}

.product-description {
  font-size: 16px; /* Increased size */
  margin: 10px 0;
}

.carousel-item img {
  max-height: 400px; /* Adjust the height as needed */
  width: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the space */
  margin: 10px;
}

/* Checkout Styles */
.checkout-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.address-section,
.order-summary,
.payment-section {
  margin-bottom: 20px;
}

.order-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.total {
  font-size: 18px;
  font-weight: bold;
}

.input-field {
  position: relative;
  margin-bottom: 20px; /* Adds space between input fields */
}

.input-field input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.input-field input:focus {
  border-color: #007bff;
}

.input-field label {
  position: absolute;
  top: -12px; /* Adjust as needed */
  left: 10px;
  font-size: 12px;
  background-color: white; /* Background color of the input field container */
  padding: 0 5px; /* Spacing for the label "cutting" effect */
  color: #353333;
  transition: all 0.2s ease-in-out;
}

.input-field input:focus + label,
.input-field input:not(:placeholder-shown) + label {
  top: -12px; /* Keep it on the border */
  font-size: 12px;
  color: #007bff;
}

.float-label-border {
  top: -12px; /* Adjust to position the label on the top border */
  font-size: 12px;
  color: #007bff;
}

.full-width-button {
  width: 100%; /* Makes button full-width */
  padding: 10px;
  background-color: #3399cc;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 15px; /* Adds some space between button and inputs */
}

.full-width-button:hover {
  background-color: #287ba6;
}

.error {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

.order-summary {
  list-style-type: none;
  padding: 0;
}

.order-summary-item {
  display: flex;
  align-items: center; /* Align items vertically */
  margin-bottom: 10px; /* Space between items */
}

.order-summary-image {
  width: 50px;  /* Adjust this value as needed */
  height: auto; /* Maintains aspect ratio */
  margin-right: 10px; /* Space between the image and product name */
}

.order-summary-details {
  display: flex;
  justify-content: space-between; /* Space between name and quantity controls */
  align-items: center; /* Aligns items vertically */
  width: 100%; /* Allows the name and quantity controls to use available space */
}

/* Automation Pages Styles */
.pagination-container {
  text-align: center;
  margin: 20px 0;
}

.pagination-container button {
  margin: 0 5px;
  padding: 5px 10px;
}

.pagination-container .active {
  background-color: #007bff;
  color: white;
}

/* App Styles */
.product-image {
  width: 100%;
  height: 400px; /* Set a fixed height for the image container */
  object-fit: contain; /* Ensures the image fits the container while maintaining its aspect ratio */
  border: 1px solid #ddd; /* Optional: Add a border to highlight the image container */
  padding: 10px; /* Optional: Padding to give the image some space */
}

.product-details {
  display: flex;
  flex-direction: column;
}

.product-quantity {
  margin: 0 5px;
}

.product-price {
  margin-top: 5px;
}

.total-price {
  margin-top: 20px;
  font-weight: bold;
}

.price-and-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  margin: 0; /* Adjust margins as needed */
}

/* Checkout CSS Ends */


/* Border around the tabs section */
.border-tabs {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 15px;
}

/* Customize the tab styles */
.custom-nav .nav-link {
  color: #333;
  background-color: #f8f9fa;
  border: none;
  margin-right: 5px;
  border-radius: 5px;
  padding: 10px 15px;
  transition: all 0.3s ease;
}

/* Hover effect to change color */
.custom-nav .nav-link:hover {
  color: #fff;
  background-color: #28a745; /* Green color on hover */
}

/* Active tab color */
.custom-nav .nav-link.active {
  color: #fff;
  background-color: #28a745; /* Green color for active tab */
  font-weight: bold;
  border: 2px solid #28a745;
  border-radius: 5px;
}

/*tab css ends*/