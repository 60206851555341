.container {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-around;
}

.main {
  width: 100%;
  flex-wrap: wrap;
}

.contact input[type="text"],
.contact textarea,
.contact button[type="submit"] {
  font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
}

.contact {
  background: #F9F9F9;
  padding: 25px;
  margin: 15px 0;
  width: 30%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.contact h3 {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}

.success {
  color: #270;
  background-color: #DFF2BF;
}

.contact h4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

.contact input[type="text"],
.contact textarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;
}

.addBtn {
  background: green;
  width: 60px;
  cursor: pointer;
  height: 60px;
  color: white;
  border-radius: 50%;
  box-shadow: 2px 4px 9px black;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact input[type="text"]:hover,
.contact textarea:hover {
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

.contact textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

.contact button[type="submit"] {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #4CAF50;
  color: #fff;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}

.contact button[type="submit"]:hover {
  background: #43A047;
  transition: background 0.3s ease-in-out;
}

.contact button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.contact input:focus,
.contact textarea:focus {
  outline: none;
  border: 2px solid #4CAF50; /* Make the border thicker and change color */
}

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}

@media (max-width: 991px) {
  .main {
      width: 100%;
      display: block;
  }
}

.table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  border: 1px solid #ddd; /* Add border to the table */
}

.table th,
.table td {
  padding: 12px; /* Add padding for better spacing */
  text-align: left;
}

.table th {
  background-color: #f2f2f2; /* Light background for the header */
  font-weight: bold;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9; /* Alternate row color */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd; /* Border around table cells */
}

.table-dark th {
  background-color: #343a40; /* Dark background for the header */
  color: white; /* White text for header */
}

.table-dark td {
  color: black; /* Black text for body cells */
}
