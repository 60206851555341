/* Define the animation for the fade-in effect */
.fade-in {
    opacity: 0;
    transition: opacity 2.5s ease-in-out;
  }
  
  .fade-in.show {
    opacity: 1;
  }

  
  .btn:hover {
    background-color: #0056b3; /* Color on hover */
   
  }